<template>
<v-container lighten-5>
      <v-row>
        <v-col cols="12" sm="12" md="12">
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="tabla">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <v-container class="pt-0">
                            <v-row align="center" justify="center" :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                <v-col v-for="item in items" :key="item.comaId" cols="12" sm="12" md="10" lg="10">
                                    <v-card elevation="0" class="rounded-xl">
                                        <v-card-title :class="`${($vuetify.breakpoint.mdAndUp)?'subtitle-1':'subtitle-2'}  font-weight-bold`">
                                            <v-row no-gutters>
                                                <v-col cols="10" sm="10" md="10" lg="10">
                                                    <v-row no-gutters>
                                                        <template v-for="(p,i) in tabla.primarios">
                                                            <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                {{p.titulo}} <span :class="p.class">
                                                                    {{ p.valor(item) }}
                                                                </span>
                                                            </v-col>
                                                        </template>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="2" sm="2" md="2" lg="2">
                                                    <v-menu bottom left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <template v-for="(m, i) in tabla.menu">
                                                                <template v-if="m.show(item)">
                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item v-else :key="i" @click="m.action(item)">
                                                                        <v-list-item-icon>
                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                    </v-list-item>
                                                                </template>
                                                            </template>
                                                        </v-list>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row no-gutters>
                                                <template v-for="(p,i) in tabla.segundarios">
                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                        {{p.titulo}} <span :class="p.class">
                                                            {{ p.valor(item) }}
                                                        </span>
                                                    </v-col>
                                                </template>
                                                <v-col cols="12" sm="12" md="12" lg="12" v-if="tabla.expandible">
                                                    <v-row>
                                                        <v-col cols="2" sm="2" md="1" lg="1">
                                                            <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                                <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                                <v-icon v-else>mdi-chevron-up</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="10" sm="10" md="11" lg="11">
                                                            <span class="headline primary--text">{{tabla.expandibleTitle(item)}}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <template v-if="tabla.expandible">
                                            <v-divider v-if="isExpanded(item)"></v-divider>
                                            <v-card-text v-if="isExpanded(item)">
                                                <v-container>
                                                    <template v-for="items in tabla.expandibleItems(item) ">
                                                        <v-sheet min-height="100%" width="100%" :key="items[tabla.expandibleKey]">
                                                            <v-row no-gutters :class="`${($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'} mb-5 py-2 px-2`" >
                                                                <template v-for="(p,i) in tabla.expanItem">
                                                                    <v-col :cols="p.tamaño.cols" :sm="p.tamaño.sm" :md="p.tamaño.md" :lg="p.tamaño.lg" :key="i">
                                                                        {{p.titulo}} <span :class="p.class">
                                                                            {{ p.valor(items) }}
                                                                        </span>
                                                                    </v-col>
                                                                </template>
                                                                <v-col cols="2" sm="2" md="2" lg="2" v-if="tabla.expanMenu.length > 0">
                                                                    <v-menu bottom left>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                                <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-list>
                                                                            <template v-for="(m, i) in tabla.expanMenu">
                                                                                <template v-if="m.show(items)">
                                                                                    <v-list-item v-if="m.tipeAction=='link'" :key="i" :to="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                    <v-list-item v-else :key="i" @click="m.action(items)">
                                                                                        <v-list-item-icon>
                                                                                            <v-icon :color="m.color">{{m.icon}}</v-icon>
                                                                                        </v-list-item-icon>
                                                                                        <v-list-item-title>{{ m.title }}</v-list-item-title>
                                                                                    </v-list-item>
                                                                                </template>
                                                                            </template>
                                                                        </v-list>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                        </v-sheet>
                                                    </template>
                                                </v-container>
                                            </v-card-text>
                                        </template>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>
    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent width="1200px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-btn text class="mb-2 primary--text" @click="agregarHorario" v-if="isNew">Agregar nuevo</v-btn>
                            </v-col>
                        </v-row>
                        <template v-if="form.length > 0">
                            <v-row  v-for="(items , index) in form" :key="index">
                            <v-col cols="12" sm="12" md="3">
                                <v-text-field dense outlined :error-messages="errores[`[${index}].cpehTurnosnormales`]" type="number" label="Turnos normales por hora" v-model="items.cpehTurnosnormales"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-text-field dense outlined  :error-messages="errores[`[${index}].cpehTurnosprioritarios`]" type="number" label="Turnos prioritarios" v-model="items.cpehTurnosprioritarios"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete dense outlined :error-messages="errores[`[${index}].cpehTipoDia`]" v-model="items.cpehTipoDia" :items="tipodia" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Dia" clearable></v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete dense outlined :error-messages="errores[`[${index}].cpehTipoEstado`]" v-model="items.cpehTipoEstado" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                               <v-menu
                                        :ref="'menuinicial'+'_'+index"
                                        v-model="items.modal1"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="items.cpehHoraInicio"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :error-messages="errores[`[${index}].cpehHoraInicio`]"
                                            v-model="items.cpehHoraInicio" label="Hora inicial" append-icon="mdi-clock-time-four-outline" readonly
                                            v-bind="attrs"
                                            dense outlined
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker v-if="items.modal1" v-model="items.cpehHoraInicio" format="24hr" full-width
                                     @click:minute="$refs[('menuinicial'+'_'+index)][0].save(items.cpehHoraInicio)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                               <v-menu
                                        :ref="'menufinal'+'_'+index"
                                        v-model="items.modal2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="items.cpehHoraFinal"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :error-messages="errores[`[${index}].cpehHoraFinal`]"
                                            v-model="items.cpehHoraFinal" label="Hora final" append-icon="mdi-clock-time-four-outline" readonly
                                            v-bind="attrs"
                                            dense outlined
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker v-if="items.modal2" v-model="items.cpehHoraFinal" format="24hr" full-width
                                        @click:minute="$refs[('menufinal'+'_'+index)][0].save(items.cpehHoraFinal)"
                                    >
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-text-field dense outlined :error-messages="errores[`[${index}].cpehTurnosHora`]" type="number" label="Intervalo (minutos)" v-model="items.cpehTurnosHora"></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="4">
                                <v-autocomplete outlined :rules="nameRules" :error="$v.form.$each[index].cpehTipoJornada.$error" v-model="items.cpehTipoJornada" :items="tipojornada" item-text="comaNombrecorto" item-value="comaId" item-color="primary" label="Jornada" clearable></v-autocomplete>
                            </v-col> -->
                            
                            <v-col cols="12" sm="12" md="1" v-if="form.length > 1">
                                <v-btn text icon color="error" v-if="isNew" @click="eliminarHorario(items)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        </template>
                       
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary" dark :loading="loadingGuardar" @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>

</v-container>
</template>

<script>
import generales from '../../../mixins/generales.js';
export default {
    mixins: [generales],
    name: "Horarios",
    props: {
        coprId: Number,
    },
    data() {
        return {
            url: "sistema/horarios/",
            form: [],
             tabla: {
                itemKey: 'cpehId',
                orderBy: 'cpehId',
                expandible: false,
                expandibleKey: 'cpehId',
                primarios: [{
                        titulo: 'TURNOS NORMALES',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.cpehTurnosnormales}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'TURNOS PRIORITARIOS',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.cpehTurnosprioritarios}` },
                        show: (item) => { return item ? true : false }
                    },{
                        titulo: 'DIA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.dia?item.dia.comaNombrelargo:'' },
                        show: (item) => { return item ? true : false }
                    },

                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.estado?item.estado.comaNombrecorto:'' },
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [{
                        titulo: 'HORA INICIAL',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.cpehHoraInicio },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'HORA FINAL',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.cpehHoraFinal },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'INTERVALO(MIN)',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.cpehTurnosHora},
                        show: (item) => { return item ? true : false }
                    },
                    
                ],
                expanItem: [],
                expanMenu: [],
                menu: [ {
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'funcion',
                        action: (item) => { return  this.editar(item.cpehId) },
                        show: (item) => { return item ? this.getPermiso('editarHorarios') : false }

                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.cpehId) },
                        show: (item) => { return item ? this.getPermiso('eliminarHorarios') : false }

                    },
                ]
            },
            filtros: [{
                    campo: "dia",
                    text: "Dia",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                },
                {
                    campo: "estado",
                    text: "Estado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "comaNombrecorto",
                    listavalue: "comaId"
                }
            ],
            tipodia: [],
            tipoestado: [],
        };
    },
    created: async function () {
        let {tipodia,tipoestado } = await this.$apiService.index("sistema/horarios/generalesCreateHorarios").then(data => data);
        this.filtros[0].lista = this.tipodia = tipodia;
        this.filtros[1].lista = this.tipoestado = tipoestado;
        await this.search(this.filtros);
        this.agregarHorario()
    },
    methods: {
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            const data = await this.$apiService.index(`sistema/horarios/buscar/${this.coprId}?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
        async agregar() {
             try {
                this.errores = [];
                this.loadingGuardar = true;
                let data = (this.isNew) ? await this.$apiService.create(`${this.url}create`, this.form).then((data) => data) :
                    await this.$apiService.update(`${this.url}update/${this.form[0].cpehId}`, this.form[0]).then((data) => data);
                if (data) {
                    this.$swal.fire({
                    title: "Completado!",
                    text: "Registro exitoso",
                    icon: "success",
                    confirmButtonText: "Ok"
                    });
                    await this.search(this.filtros);
                    this.close();
                }

            } catch (error) {
                if (error.response && 422 == error.response.status) {
                    this.errores = error.response.data.errors;
                } else {
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                        this.$swal.fire({
                            title: "Algo sucedio!",
                            text: "contacta con el administrador",
                            icon: "error"
                        });
                    }

                }
            }
            this.loadingGuardar = false;
        },
        async eliminar(id) {
            try {
                let result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    var data = await this.$apiService.delete(`${this.url}delete/${id}`).then(data => data);
                    if (data) {
                        await this.search(this.filtros);
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
        },
        async editar(id) {
            try {
                var data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
                if (data) {
                    this.form = [];
                    this.form.push(data);
                }
                this.isNew = false;
                this.dialog = true;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
        },
        agregarHorario() {
            this.form.push({
                cpehId: null,
                cpehTurnosnormales: null,
                cpehTurnosprioritarios: null,
                cpehTipoDia: null,
                cpehTipoJornada: null,
                cpehTipoEstado: null,
                coprId: this.coprId,
                modal2:false,
                modal1:false,
            });
        },
        eliminarHorario(item) {
            this.form = this.form.filter(e => this.form.indexOf(e) != this.form.indexOf(item));
        },
        limpiar() {
            this.form = [{
                cpehId: null,
                cpehTurnosnormales: null,
                cpehTurnosprioritarios: null,
                cpehTipoDia: null,
                cpehTipoJornada: null,
                cpehTipoEstado: null,
                coprId: this.coprId,
                modal2:false,
                modal1:false,
            }];
        },
        close() {
            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>

<style>

</style>
